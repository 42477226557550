var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.editorProp)?_c('div',[_c('button',{staticClass:"purple-btn",class:{ 'is-active': _vm.editorProp.isActive('bold') },on:{"click":function($event){_vm.editorProp
          .chain()
          .focus()
          .toggleBold()
          .run()}}},[_vm._v(" bold ")]),_c('button',{staticClass:"purple-btn",class:{ 'is-active': _vm.editorProp.isActive('italic') },on:{"click":function($event){_vm.editorProp
          .chain()
          .focus()
          .toggleItalic()
          .run()}}},[_vm._v(" italic ")]),_c('button',{staticClass:"purple-btn",class:{ 'is-active': _vm.editorProp.isActive('strike') },on:{"click":function($event){_vm.editorProp
          .chain()
          .focus()
          .toggleStrike()
          .run()}}},[_vm._v(" strike ")]),_c('button',{staticClass:"purple-btn",class:{ 'is-active': _vm.editorProp.isActive('bulletList') },on:{"click":function($event){_vm.editorProp
          .chain()
          .focus()
          .toggleBulletList()
          .run()}}},[_vm._v(" bullet list ")]),_c('button',{staticClass:"purple-btn",on:{"click":_vm.toggleLink}},[_vm._v(" link ")]),_c('button',{staticClass:"purple-btn",class:{ 'is-active': _vm.isListening },on:{"click":_vm.toggleRecognition}},[_vm._v(" "+_vm._s(_vm.isListening ? 'Stop Voice Input' : 'Start Voice Input')+" ")])]):_vm._e(),_c('editor-content',{attrs:{"editor":_vm.editorProp,"id":_vm.numberProp === 1 ? 'rich-editor' : 'rich-editor2'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }